import { Breakpoint, hdeApp } from '@/modules/hde-app';

/**
 * Checks if element is visible in the viewport
 *
 * https://stackoverflow.com/a/7557433
 */
export function isInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left >= 0 &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function isElementInViewportByThreshold(element: HTMLElement, thresholdPercentage: number = 30): boolean {
    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    const boxWidthInViewport =
        (rect.width + rect.left > viewportWidth ? viewportWidth : rect.width + rect.left) -
        (rect.left < 0 ? 0 : rect.left);
    const boxHeightInViewport =
        (rect.height + rect.top > viewportHeight ? viewportHeight : rect.height + rect.top) -
        (rect.top < 0 ? 0 : rect.top);

    const area = boxWidthInViewport * boxHeightInViewport;

    const percentageInViewport = (area / (rect.height * rect.width)) * 100;

    return percentageInViewport >= thresholdPercentage;
}

/**
 * offsetWidth and offsetHeight will return 0 if the element, or any of its ancestors have display 'none'.
 * Similarly, an element that is hidden via display 'none' does not have any bounding client rectangles. Hence,
 * getClientRects() will be an empty array.
 */
export function isElementVisible(el: HTMLElement): boolean {
    return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
}

export function isMobileHeader() {
    return hdeApp.viewportIsSmallerThanBreakpoint(Breakpoint.MD);
}

export function isMobilePhoneViewport() {
    return hdeApp.viewportIsSmallerThanBreakpoint(Breakpoint.SM);
}

export function isMobileFooter() {
    return hdeApp.viewportIsSmallerThanBreakpoint(Breakpoint.MD);
}

export function setHeaderWrapperHeight(): void {
    const parent = document.querySelector('#header')?.parentElement;
    if (!parent) {
        return;
    }
    if (!isMobileHeader()) {
        parent.style.height = 'auto';
        return;
    }
 
    const trustBar = document.querySelector<HTMLElement>('#header .trust-bar');
    const searchBar = document.querySelector<HTMLElement>('#header .search-bar');

    const trustBarHeight = (trustBar && (trustBar.clientHeight || parseInt(window.getComputedStyle(trustBar).height))) || 0;
    const searchBarHeight = (searchBar && (searchBar.clientHeight || parseInt(window.getComputedStyle(searchBar).height))) || 0;

    const totalHeight = trustBarHeight + searchBarHeight;

    parent.style.height = `${totalHeight}px`;
}
